"use client";

import { EmptyState } from "@spheron/ui-library";
import HomeIcon from "@spheron/ui-library/dist/assets/home-4.svg";
import React from "react";

const NotFoundPage = () => {
  return (
    <div className="w-full h-96 mt-20">
      <EmptyState
        entityTitle="Some Error Ocurred"
        showButton
        buttonDisabled={false}
        buttonText="Go back"
        // buttonIcon={<HomeIcon className="w-5 h-5" />}
        showButtonIcon={false}
        onClick={() => window.open("/", "_self")}
      />
    </div>
  );
};

export default NotFoundPage;
